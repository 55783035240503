.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 35px;
    width: 100%;
    height: 100vh;
    background-color: #B5E0E1;
    img {
        width: 180px;
    }
    p {
      font-size: 50px;
    }
}



.Contact img, 
.Contact h1, 
.Contact h2,
.Contact p {

    animation: slideInFromLeft 1.5s ease-in-out forwards;
    opacity: 0;
}

h1, 
h2 {
  font-family: "Montserrat", sans-serif;;
}

.accounts img{
 width: 50%;
}

.formWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
form{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 70%;
  background-color: grey;
  border-radius: 2.5rem;
  padding: 5px;
  }
}

input[type="text"]{
  width: 80%;
  font-size: 20px;
}
input[type="email"]{
  width: 80%;
  font-size: 20px;
}
textarea[name="message"]{
  width: 80%;
  max-width: 80%;
  font-size: 20px;
}
button[type="submit"]{
  width: 50%;
  cursor: pointer;
  font-size: 20px;
  border-radius: 2.5rem;
  border: none;
}
button[type="submit"]:hover{
  background-color: green;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (min-width: 821px) and (max-width: 1558px){
    .Contact {
      height: 100vh;
    }

    .Contact h1, .Contact h2, .Contact a, .socials{
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 1rem;
      margin: 0;
      font-size: 35px;
    }
    .Contact p {
      font-size: 1em;
    }
    .Contact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }
    .accounts {
      display: flex;
      img {
      width: 5rem;
    }
  }
  }

  @media (min-width: 436px) and (max-width: 820px){
    .Contact {
      height: 100vh;
      justify-content: space-between;
      img {
        width: 10rem;
      }
    }

    .Contact h1, .Contact h2, .Contact a, .socials{
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 1rem;
      margin: 0;
      font-size: 30px;
    }
    .Contact p {
      font-size: 1.5em;
    }
    .Contact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }
    .accounts {
      display: flex;
    img {
      width: 5rem;
    }
  }
  .formWrapper form{
    width: 40%;
  }
  
  }

  @media (min-width: 394px) and (max-width: 435px){
    .Contact {
      height: 100%;
      img {
        width: 6rem;
      }
    }
    .Contact h1, .Contact h2, .Contact a, .socials {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 1.2rem;
      margin: 0;
      font-size: 20px;
    }
    .Contact p {
      font-size: .95em;
    }
    .contact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }

    .accounts img {
      width: 6rem
    }
    .formWrapper {
      flex-direction: column;
      padding: 3px;
    form{
      flex-direction: column;
      width: 60%;
      padding: 5px;
      }
    }
    
  }

  @media (min-width: 200px) and (max-width: 393px) {
    .Contact {
      height: 100%;
      img {
        width: 6rem;
      }
    }

    .Contact h1, .Contact h2, .Contact a, .socials {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 1.2rem;
      margin: 0;
      font-size: 20px;
    }
    .Contact p {
      font-size: .8em;
    }
    .contact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }
    .gmailImg {
      font-size: 20rem;
    }
    .accounts img {
      width: 6rem
    }
    .formWrapper {
      flex-direction: column;
    form{
      width: 60%;
      }
    }
  }

  @media (min-width: 786px) and (max-height:435px) and (orientation: landscape){
    .Contact {
      height: 100%;
      width: 99.64%;
      img {
        width: 6rem;
      }
    }
    .Contact h1, .Contact h2, .Contact a, .socials {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 1.2rem;
      margin: 0;
      font-size: 20px;
    }
    .Contact p {
      font-size: .95em;
    }
    .contact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }

    .accounts img {
      width: 6rem
    }
    .formWrapper {
      flex-direction: column;
      padding: 3px;
    form{
      flex-direction: column;
      width: 60%;
      padding: 5px;
      }
    }
  }