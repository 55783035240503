.Work {
    display: flex;
    font-size: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #B5E0E1;
    width: 100%;
    overflow-x: hidden;
    h1 {
        font-size: 70px;
        color: #203845;
        font-family: "Montserrat", sans-serif;
    }
  }

  @media (max-width: 768px) {
    .Work {
      h1 {
        font-size: 40px;
      }
    }
  }
