.gif {
    width: 40em; 
    margin: 55px;
    height: 80%;
    padding: auto;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    height: auto;
    width: 90%;
    align-items: center;
}

.preview h1{
    font-size: 1em;
}

.icons {
    width: 8%;
}

.preview {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    color: black;
    text-align: center;
    align-items: center;
    margin: 15px;
    padding: 5px;
}

.detailsButton {
    background-color: white;
    border-radius: .8rem;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 25px;
    cursor: pointer;
}

.detailsButton:hover {
    background-color: coral;
}

h1 {
    font-family: "Montserrat", sans-serif;
}

@media (min-width: 821px) and (max-width: 1558px){
    .gif{
        width: 20em;
        height: 15em;
    }
    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 5px;
        height: auto;
        width: 90%;
    }
}

@media (min-width: 446px) and (max-width: 820px){
    .gif{
        width: 20em;
        height: 10em;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 5px;
        height: auto;
        width: 90%;
    }
}

@media (max-width: 435px) {
    .gif {
        width: 11.5em; 
        height: 40%;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 5px;
        height: auto;
        width: 90%;
    }
    .detailsButton {
        font-size: 15px;
    }
}

@media (min-width: 1080px) and (max-height: 820px) and (orientation: landscape){
    .gif {
        width: 25em; 
        height: 10%;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 2px;
        height: auto;
        width: 70%;
    }
}
@media (min-width: 1040px) and (max-height: 435px) and (orientation: landscape){
    .gif {
        width: 20em; 
        height: 10%;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 2px;
        height: auto;
        width: 70%;
    }
}

@media (min-width: 786px) and (max-height:435px) and (orientation: landscape){
    .gif {
        width: 20em; 
        height: 10%;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 2px;
        height: auto;
        width: 70%;
    }
}

@media  (min-width: 852px) and (max-height: 393px) and (orientation: landscape) {
    .gif {
        width: 16em; 
        height: 10%;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        padding: 2px;
        height: auto;
        width: 70%;
    }
  }