* {
  font-family: "Cormorant Garamond", serif;
}

.Home {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url('../images/homePagePhoto.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: fit-content;
  background-color: rgba(118, 114, 114, 0.5);
  border-radius: 10px;
}

.home-buttons {
    display: flex;
    width: fit-content;
    font-size: 20px;
    height: fit-content;
    animation: slideInFromLeft 1.5s ease-in-out forwards;
}

.button.homeButton:hover {
  background-color: #B5E0E1;
}

.button.homeButton {
  text-decoration: none;
  background-color: white;
  border-radius: 5rem;
  padding: 0.7em 2em;
  letter-spacing: 0.2em;
  height: fit-content;
  margin: 15px;
  color: black;
}

.homeHeader {
  font-size: 40px;
  font-weight: bold;
  color: white;
  animation: slideInFromLeft 1.5s ease-in-out forwards;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

@media (max-width: 435px) {
  .contentContainer {
    width: 80%;
    background-color: rgba(118, 114, 114, 0.5);
  }
  .homeHeader {
    font-size: 25px;
  }
  .home-buttons {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 10px;
    width: fit-content;
  }
}

@media (min-width: 436px) and (max-width: 975px) {
  .Home {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contentContainer {
    display: flex;
    width: 80%;
  }
  .homeHeader {
    font-size: 35px;
  }
  .home-buttons {
    text-align: center;
    font-size: 15px;
  }
}
