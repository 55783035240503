.nav {
  display: flex;
  justify-content: space-between;
  height: 8vh;
  align-items: center;
  font-size: 24px;
  padding: .5em 1em;
}

.menu-button {
  height: fit-content;
  cursor: pointer;
  color: #B5E0E1;
  width: fit-content;
  background: none;
  border: none;
  transition: transform 0.3s ease-in-out;
}

.menu-button svg {
  width: 3rem;
  height: 5rem;
}

.toggle-on {
  transform: rotate(90deg);
}

.toggle-off {
  transform: rotate(0deg);
}

.dropdown {
  position: absolute;
  top: 8;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  border-radius: 0.25rem;
  z-index: 1000;
  opacity: 0;
  width: fit-content;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.dropdown.expanded {
  opacity: 1;
  visibility: visible;
}

.nav-location {
  background: none;
  font-size: 1.5em;
  padding: .5em;
  letter-spacing: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #3B3937;
}

.nav-location:hover {
  color: #B5E0E1;
  border-bottom: 2px solid #B5E0E1;
}

.location-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.location-container span {
  flex-grow: 1;
  text-align: left;
  margin-left: 0.5em;
}

.navIcon {
  width: 10%;
}

.location-container span {
  flex-grow: 1;
  text-align: right;
  margin-right: 0.5em;
  width: fit-content;
}

@media (max-width: 820px) {
  .nav {
    height: 2.5em;
    font-size: 1em;
  }
}


@media (min-width: 393px) and (max-height: 1180px) and (orientation: landscape){
  .nav {
    height: 2.5em;
    font-size: 1em;
  }
}

@media (max-width: 975px) and (min-height: 1558px){
  .nav {
    height: 2.5em;
    font-size: 1em;
  }
}