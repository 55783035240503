.About-wrapper {
  max-width: 100%;
  height: 100%; 
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  h1 {
    font-size: 80px;
  }
}

.second-wrapper {
  max-width: 80vw; 
  display: flex;
  flex-direction: row; 
  align-items: center; 
  p {
    font-size: 35px;
  }
}

.About-image {
  margin: 0 10rem 2rem 0;
  width: 30%; 
  max-width: 100%; 
}

.button.workLink {
  text-decoration: none;
  background-color: white;
  border-radius: 5rem;
  padding: 1rem 2rem;
  margin: 5px;
  letter-spacing: 0.4em;
  font-size: 20px;
  color: black;
  text-align: center;
}
.button.workLink:hover {
  background-color: #B5E0E1;
}
.navigation-links {
  display: flex;
  flex-direction: row;
  width:100%;
  margin: 5px;
  font-weight: bold;
  justify-content: space-evenly;
  text-align: center;
}
@media (min-height: 1080px) and (max-width: 1920px){
  .About-wrapper {
    display: flex;
    height: fit-content;
    h1 {
      font-size: 50px;
    }
  }
  
  .second-wrapper {
    display: flex;
    flex-direction: row;
  p { 
      font-size: 25px
    }
  }
  
  .About-image {
    margin: 0 10rem 2rem 0;
    width: 50%; 
  }

  .button.workLink {
    font-size: 20px;
  }
}

@media (min-width: 821px) and (max-width: 1558px){
  .About-wrapper {
    display: flex;
    height: fit-content;
    h1 {
      font-size: 60px;
    }
  }
  
  .second-wrapper {
    display: flex;
    flex-direction: row;
  p { 
      font-size: 30px
    }
  }
  
  .About-image {
    margin: 0 10rem 2rem 0;
    width: 50%; 
  }

  .button.workLink {
    font-size: 20px;
  }
  .navigation-links {
    width: 100%
  }
}

@media (min-width: 481px) and (max-width: 820px){
  .About-wrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    text-align: start;
    align-content: center;
    justify-content: center;
    h1 {
      font-size: 70px;
    }
  }
  
  .second-wrapper {
    display: flex;
    flex-direction: column;
    width: 90vw;
  p { 
      font-size: 30px
    }
  }
  
  .About-image {
    width: 90%; 
    margin-left: 10rem;
  }

  .button.workLink {
    font-size: 20px;
    margin: 5px;
    text-align: center;
    align-content: center;
  }

  .navigation-links {
    font-size: 30px;
    justify-content: center;
  }
  
}


@media (max-width: 480px){
  .About-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    h1 {
      font-size: 45px;
    }
  }

  .second-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-content: center;
    align-items: center;
  }

  .second-wrapper p {
    font-size: 30px;
  }
  
  .About-image {
    width: 70%; 
    margin-left: 10rem;
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button.workLink {
    margin-right: 1rem;
    font-size: 14px;
    text-align: center;
    width: fit-content;
  }
  
}

/* @media (min-width: 350px) and (max-width: 393px){
  .About-wrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    text-align: center;
    h1 {
      font-size: 40px;
    }
  }
  
  .second-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-content: center;
    align-items: center;
  }

  .second-wrapper p {
    font-size: 25px;
  }
  
  .About-image {
    width: 70%; 
    margin-left: 10rem;
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
  }

  .button.workLink {
    margin-right: 1rem;
    font-size: 14px;
    text-align: center;
  }
} */
@media (min-width: 1080px) and (max-height: 820px) and (orientation: landscape){
  .About-wrapper {
    display: flex;
    height: fit-content;
    h1 {
      font-size: 70px;
    }
  }
  
  .second-wrapper {
    display: flex;
    flex-direction: row;
  p { 
      font-size: 30px
    }
  }
  
  .About-image {
    margin: 0 10rem 2rem 0;
    width: 50%; 
  }

  .button.workLink {
    font-size: 15px;
  }
}
@media (min-width: 1040px) and (max-height: 435px) and (orientation: landscape){
  .About-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    h1 {
      font-size: 45px;
    }
  }

  .second-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-content: center;
    align-items: center;
  }

  .second-wrapper p {
    font-size: 25px;
  }
  
  .About-image {
    width: 50%; 
    margin-left: 10rem;
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
  }

  .button.workLink {
    margin-right: 1rem;
    font-size: 1px;
    text-align: center;
  }
}
@media (min-width: 786px) and (max-height:435px) and (orientation: landscape){
  .About-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    h1 {
      font-size: 45px;
    }
  }

  .second-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-content: center;
    align-items: center;
  }

  .second-wrapper p {
    font-size: 25px;
  }
  
  .About-image {
    width: 70%; 
    margin-left: 10rem;
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
  }

  .button.workLink {
    margin-right: 1rem;
    font-size: 14px;
    text-align: center;
  }
}